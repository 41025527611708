<template>
  <div class="my-6">
    <MedicationSection />
    <NavMenuSecondary page="medication" />
  </div>
</template>

<script>
import MedicationSection from "../components/healthlogoverview/medication/MedicationSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue"


export default {
  components: {
    MedicationSection,
    NavMenuSecondary
  },
};
</script>