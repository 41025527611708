<template>
  <div>
    <div class="flex justify-start items-end">
      <div class="md:w-6/12">
        <img
          class="h-20 ml-28 mt-8 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal
          :titlemenu1="titlemenu1"
          :to1="goTo()"
          titlemenu2="Health Log"
          :to2="'/healthlogoverview'"
          titlemenu3="Medication"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex justify-center">
      <MedicationTable :medications="medicationTracking" />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import MedicationTable from "./MedicationTable.vue";
import { mapGetters, mapActions } from "vuex";
import { nameOfPet } from "../../../methods/petMethods";

export default {
  components: {
    HeaderGlobal,
    MedicationTable,
  },
  data() {
    return {
      pet: {},
      titlemenu1: "",
      medications: [
        {
          medication_name: "Diabetes",
          frequency: "3x daily",
          duration: " days",
          dosage: "200mg",
          first_intake: "25.12.2022",
          reminder: true,
        },
        {
          medication_name: "Kennel Cough (Bordatella)",
          frequency: "1x monthly",
          duration: "forever",
          dosage: "120mg",
          first_intake: "14.03.2021",
          reminder: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      medicationTracking: "healthlog/getMedicationTracking",
    }),
  },
  created() {
    const petObject = localStorage.getItem("pet");
    const pet = JSON.parse(petObject);
    this.pet = pet;

    this.titlemenu1 = nameOfPet(pet.species) + " record: " + pet.callName;
    this.getMedicationTrackingOfPet(pet.petID);
  },
  methods: {
    ...mapActions({
      getMedicationTrackingOfPet: "healthlog/getMedicationTrackingOfPet",
    }),

    goTo() {
      return "/petrecord";
    },
  },
};
</script>

<style></style>
