<template>
  <div class="w-full md:w-10/12 overflow-x-auto">
    <ul
      class="bg-white py-4 text-primary text-left font-proximaMedium rounded-sm flex items-center"
    >
      <li class="w-3/12 pl-4">Medication Name</li>
      <li class="w-1/12 text-center">First Intake</li>
      <li class="w-2/12 text-center">Frequency</li>
      <li class="w-1/12 text-center">Duration</li>
      <li class="w-2/12 text-center">Dosage</li>
      <li class="w-3/12 text-left">Reminder Notifications</li>
    </ul>
    <ul
      v-for="(data, i) in medications"
      :key="i"
      class="py-6 border-b border-primary text-sm font-proximaLight flex items-center justify-between"
    >
      <li class="w-3/12 px-2 flex items-center justify-between text-primary">
        {{ data.medication }}
      </li>
      <li class="w-1/12 text-center">
        {{ data.first_intake }}
      </li>
      <li class="w-2/12 text-center">
        {{ data.frequency_times + " X " + data.frequency }}
      </li>
      <li class="w-1/12 text-center">
        {{
          data.duration_forever
            ? "forever"
            : data.duration_in_days
            ? data.duration_in_days + " days"
            : data.duration_in_days_taken
            ? data.duration_in_days_taken + " weeks"
            : ""
        }}
      </li>
      <li class="w-2/12 text-center">{{ data.brand_dosage }}</li>
      <li class="w-3/12 flex items-center relative">
        {{ data.notification ? "On" : "Off" }}
        <RadioOnoffBorder
          :val="data"
          :checked="data.notification"
          @inputVal="getInputVal"
        />
        <TrashIcon
          iconColor="#0AC0CB"
          bgColor="#fff"
          width="26"
          height="26"
          class="cursor-pointer absolute right-4 -top-1"
          @click.native="deleteFiles(data)"
        />
      </li>
    </ul>

    <AddIcon
      @click.native="gotoAddMedication"
      class="mt-8 cursor-pointer"
      width="62"
      height="62"
    />
  </div>
</template>

<script>
import TrashIcon from "../../icons/TrashIcon.vue";
import AddIcon from "../../icons/AddIcon.vue";
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import IconButton from "../../buttons/IconButton.vue";
import FormError from "../../warning/FormError.vue";
import InputRadio from "../../forms/InputRadio.vue";

export default {
  props: ["medications"],
  components: {
    TrashIcon,
    AddIcon,
    RadioOnoffBorder,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    IconButton,
    InputRadio,
    FormError,
  },
  data() {
    return {
      toggleReminder: "",
    };
  },
  computed: {},
  created() {},
  methods: {
    async getInputVal(i) {
      this.toggleReminder = !this.toggleReminder;
      console.log({ i });
    },
    gotoAddMedication() {
      this.$router.push({ path: "/healthlogoverview/addmedication" });
    },
  },
};
</script>

<style>
.mx-datepicker-popup {
  z-index: 9999;
}
</style>